<template>
  <div>
    <BaseInput
      v-model="formOptions.name"
      label="Node name"
    />

    <b-field
      :type="isDuplicateCustomURL ? 'is-danger' : ''"
      :message="isDuplicateCustomURL ? 'Duplicate Custom URL' : ''"
    >
      <BaseInput
        v-model="formOptions.custom_url_name"
        label="Custom URL name"
      />
    </b-field>

    <b-checkbox
      v-model="formOptions.node_direct_access"
      size="is-small"
      class="has-text-grey"
    >
      Direct Access
    </b-checkbox>

    <BaseSelect
      v-model="formOptions.layout"
      label="Template"
    >
      <option :value="null">
        -- No template --
      </option>
      <option
        v-for="(layout, lIndex) in layouts"
        :key="'layout' + lIndex"
        :value="layout.id"
      >
        {{ layout.name }}
      </option>
    </BaseSelect>
  </div>
</template>

<script >
// libs
import { computed, onMounted, ref, watch } from '@vue/composition-api';
// components
import BaseInput from '@/modules/core/components/generics/BaseInput.vue';
import BaseSelect from '@/modules/core/components/generics/BaseSelect.vue';
// stores
import { useModuleGraphStore } from '@/modules/builder/store/moduleGraphStore';
// services
import { fetchLayoutService } from '@/services/application-service/layoutRequests';
// composables
import { useRoute } from '@/hooks/vueRouter';
// misc
import { generateFormOptions } from '@/modules/builder/components/module-sidebar/screen/shared/screenUtils';
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  // composables specific to this component
  const useLayoutManager = () => {
    const {
      appId
    } = route.params;
    const layouts = ref([]);
    const fetchLayouts = async () => {
      const {
        data: {
          data
        }
      } = await fetchLayoutService(appId);
      layouts.value = data;
    };
    onMounted(() => {
      fetchLayouts();
    });
    return {
      layouts
    };
  };

  // use composables
  const route = useRoute();
  const moduleGraphStore = useModuleGraphStore();
  const {
    layouts
  } = useLayoutManager();
  const defaultOptions = {
    ...generateFormOptions()
  };
  const formOptions = ref({});
  const loadOptions = () => {
    formOptions.value = {
      ...defaultOptions,
      ...props.value
    };
  };
  loadOptions();
  const isDuplicateCustomURL = computed(() => {
    return formOptions.value.custom_url_name ? moduleGraphStore.screenNodes.some(node => node.nodeId !== moduleGraphStore.selectedNodeId && node.data.custom_url_name === formOptions.value.custom_url_name) : false;
  });
  watch(() => formOptions.value, () => {
    emit('input', formOptions.value);
  }, {
    deep: true
  });
  return {
    layouts,
    formOptions,
    isDuplicateCustomURL
  };
};
__sfc_main.components = Object.assign({
  BaseInput,
  BaseSelect
}, __sfc_main.components);
export default __sfc_main;
</script>
